export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65')
];

export const server_loads = [];

export const dictionary = {
		"/": [15],
		"/(auth)/activate": [60],
		"/(app)/companies/[[company_id]]": [16,[2,3,4]],
		"/(app)/contacts": [17,[2,5]],
		"/(app)/contacts/[contact_id]": [18,[2,5]],
		"/(app)/kb": [19,[2,6]],
		"/(app)/kb/[id=integer]": [22,[2,6]],
		"/(app)/kb/[category]": [20,[2,6]],
		"/(app)/kb/[category]/[slug]": [21,[2,6]],
		"/(auth)/login": [61],
		"/(auth)/login/forgot": [62],
		"/(auth)/login/password-reset": [63],
		"/(auth)/logout": [64],
		"/(app)/reports": [23,[2,7]],
		"/(app)/reports/answers": [24,[2,7]],
		"/(app)/reports/csat": [25,[2,7]],
		"/(app)/reports/dashboard": [26,[2,7]],
		"/(app)/reports/surveys/csat": [27,[2,7]],
		"/(app)/reports/surveys/nps": [28,[2,7]],
		"/(app)/reports/table": [29,[2,7]],
		"/(app)/reports/tickets": [30,[2,7]],
		"/(app)/reports/timesheet": [31,[2,7]],
		"/(app)/settings": [32,[2,8]],
		"/(app)/settings/account": [33,[2,8]],
		"/(app)/settings/automations/[[automation_id]]": [34,[2,8,9]],
		"/(app)/settings/categories": [35,[2,8]],
		"/(app)/settings/data/[[id]]": [36,[2,8]],
		"/(app)/settings/flows": [37,[2,8,10]],
		"/(app)/settings/flows/[flow_id]": [38,[2,11]],
		"/(app)/settings/hours": [39,[2,8]],
		"/(app)/settings/integrations": [40,[2,8]],
		"/(app)/settings/integrations/360dialog": [41,[2,8]],
		"/(app)/settings/integrations/whatsapp": [42,[2,8]],
		"/(app)/settings/kb/categories": [43,[2,8,12]],
		"/(app)/settings/kb/posts": [44,[2,8,12]],
		"/(app)/settings/kb/posts/new": [46,[2,8,12]],
		"/(app)/settings/kb/posts/[post_id]": [45,[2,8,12]],
		"/(app)/settings/kb/settings": [47,[2,8,12]],
		"/(app)/settings/kb/tags": [48,[2,8,12]],
		"/(app)/settings/mailboxes": [49,[2,8]],
		"/(app)/settings/profile": [50,[2,8]],
		"/(app)/settings/security": [51,[2,8]],
		"/(app)/settings/snippets": [52,[2,8]],
		"/(app)/settings/surveys": [53,[2,8]],
		"/(app)/settings/surveys/[survey_id]": [54,[2,8]],
		"/(app)/settings/users": [55,[2,8]],
		"/(app)/settings/welcome": [56,[2,8]],
		"/(app)/settings/workflows": [57,[2,8]],
		"/(app)/surveys": [58,[2,13]],
		"/(app)/tickets/[[ticket_id]]": [59,[2,14]],
		"/(auth)/welcome": [65]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';